/**
 *
 * Asynchronously loads the component for Thankyou
 *
 */

import { lazyLoad } from 'utils/loadable';

const Thankyou = lazyLoad(
  () => import('./index'),
  module => module.Thankyou,
);

export default Thankyou;
