import styled from 'styled-components/macro';

const Page = styled.div`
  display: grid;
  grid-template-areas:
    'header-image'
    'page-content'
    'footer';
  grid-template-rows: auto minmax(430px, 100%) auto;

  @media only screen and (max-width: 1200px) {
    h3,
    .h3 {
      font-size: calc(1.258rem + 0.096vw);
    }
  }

  @media (max-width: 1200px) {
    h2,
    .h2 {
      font-size: calc(1.285rem + 0.42vw);
    }
  }
`;

export default Page;
