/**
 *
 * Asynchronously loads the component for ContactPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const ContactPage = lazyLoad(
  () => import('./index'),
  module => module.ContactPage,
);

export default ContactPage;
