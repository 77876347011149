import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
    
  }

  body { 
    width: 100%;
    height: 100vh;
    margin: 0;
    font-family: 'garamond-premier-pro', 'bilo', 'Roboto', 'Roboto Condensed', 'Montserrat','Quicksand', 'Muli', sans-serif; 
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #333333;
    text-align: left;
    background-color: #f5f7fc;
    overflow: visible;
  }

  a {
      text-decoration:none;
      font-family: 'Roboto', 'Roboto Condensed', 'Montserrat','Quicksand', 'Muli', sans-serif;
    }


  .page {
    display: grid;
    grid-template-areas:
      "header-image"
      "page-content"
      "footer";
      grid-template-rows: auto max-content auto;
      background-color: #f5f7fc;
      min-height: 100%;
  }

  /* .mapPage {
    display: grid;
    grid-template-areas:
      "header-image"
      "page-content map"
      grid-template-rows: auto max-content auto;
      background-color: #f5f7fc;
      min-height: 100%;
  }
   */
  
  @media only screen 
    and (max-width: 1200px) { 
      h3, .h3 {
        font-size: calc(1.258rem + 0.096vw);
      }
  }
  
  @media (max-width: 1200px) {
     h2, .h2 {
      font-size: calc(1.285rem + 0.42vw);
    } 
  }
`;
