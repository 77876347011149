import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { HashLink } from 'react-router-hash-link';
import GoogleAuth from 'app/components/GoogleAuth';
import { useHeaderSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectGoogleAuth } from './slice/selectors';
import menuIcon from '../../images/menu.png';

const Header = props => {
  const [user, setUser] = useState<null | any>(null);

  const { actions } = useHeaderSlice();
  const dispatch = useDispatch();

  const userData = useSelector(selectGoogleAuth);

  const getUserFromLocalStorage = async () => {
    let res = await localStorage.getItem('user');
    if (res !== null && res !== '') {
      setUser(JSON.parse(res));
    }
  };

  function myFunction() {
    let x = document.getElementById('myDIV');
    if (x !== null && x !== undefined) {
      if (x.style.display === 'none') {
        x.style.display = 'block';
      } else {
        x.style.display = 'none';
      }
    }
  }

  function myFunctionForMobile() {
    let x = document.getElementById('myDIVForMobile');
    if (x !== null && x !== undefined) {
      if (x.style.display === 'block') {
        x.style.display = 'none';
      } else {
        x.style.display = 'block';
      }
    }
  }

  const logOutFun = () => {
    let x = document.getElementById('myDIV');
    localStorage.setItem('user', '');
    if (x !== null && x !== undefined) {
      x.style.display = 'none';
      setUser(null);
    }
  };

  const callGoogleLoginApi = obj => {
    dispatch(actions.insertingGoogleAuthData(obj));
  };

  React.useEffect(() => {
    if (userData !== null) {
      // userData can be null on refresh into the state but may be user not click on logout so data will be present into localstorage
      localStorage.setItem('user', JSON.stringify(userData));
    }
    setUser(userData);
  }, [userData]);

  React.useEffect(() => {
    getUserFromLocalStorage();
  }, []);

  return (
    <StyledHeader>
      <header id="topnav">
        <div className={'container'}>
          <Link to="/" className={'title'}>
            TRAVELSIGHTED
          </Link>
          <div className={'rightContainer'}>
            <HashLink
              to="/#ourdestinations"
              className="cityBtnStyle webViewStyle"
              id="DestinationsLink"
            >
              Our Destinations
            </HashLink>
            <div className={'signInDivStyle'}>
              {/* ///////////// */}

              <div className="webViewStyle">
                {user !== null ? (
                  <div>
                    <img
                      src={user?.imageURL}
                      alt="menu button"
                      className={'userProfileImg'}
                      onClick={() => myFunction()}
                    />
                    <div id="myDIV">
                      <div className={'dropDownOptions'}>
                        <h4 className={'dropDownAnchor'}>Profile</h4>
                      </div>
                      <br />
                      <div
                        className={'dropDownOptions'}
                        onClick={() => logOutFun()}
                      >
                        <h4 className={'dropDownAnchor'}>LogOut</h4>
                      </div>
                      <br />
                    </div>
                  </div>
                ) : (
                  <div className={'googleAuthDivStyle'}>
                    <GoogleAuth logInSuccess={callGoogleLoginApi} />
                  </div>
                )}
              </div>

              <div className="mobileViewStyle">
                <div>
                  <img
                    src={menuIcon}
                    alt="Menu"
                    className={'userProfileImg'}
                    onClick={() => myFunctionForMobile()}
                  />
                  <div id="myDIVForMobile">
                    <nav>
                      <ul>
                        <li>
                          <HashLink
                            to="/#ourdestinations"
                            className="cityBtnStyle"
                          >
                            Our Destinations
                          </HashLink>
                        </li>
                        {user !== null ? (
                          <li
                            className="cityBtnStyle"
                            onClick={() => logOutFun()}
                          >
                            Logout
                          </li>
                        ) : (
                          <li>
                            <GoogleAuth logInSuccess={callGoogleLoginApi} />
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>

              {/* /////////////////// */}
            </div>
          </div>
        </div>
      </header>
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.header`
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    margin-bottom: 20px;
  }
  .title {
    color: #000000;
    font-family: Julius Sans One;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 700;
    padding-top: 10px;
  }
  .rightContainer {
    display: flex;
    flex-direction: row;
  }
  #topnav {
    height: 70px;
    background-color: #ffffff;
    border-bottom: 2px medium black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding-left: 2%;
    padding-right: 2%;
    position: relative;
    z-index: 200;
    // width: -webkit-fill-available;
  }
  #topnav .navigation-menu > li > a {
    color: #000000;
  }
  .menuList {
    margin-top: -10;
  }
  .signInDivStyle {
    margin-left: 20px;
  }
  .googleAuthDivStyle {
    margin-top: 10px;
  }

  .loggedIn {
    margin-top: 1.5vh;
  }
  .googleAuth {
    padding-top: 0.5vh;
  }
  .logo {
    grid-area: logo;
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    display: inline-block;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-family: 'Julius Sans One';
    font-size: 30px;
    text-transform: uppercase;
  }
  .userProfileImg {
    margin-top: 12px;
    height: 30px;
    border-radius: 30px;
  }
  #myDIV {
    width: 8vw;
    text-align: center;
    background-color: white;
    position: absolute;
    color: black;
    display: none;
    border-radius: 5px;
    line-height: 0;
    z-index: 1;
  }
  #myDIVForMobile {
    margin-left: -140px;
    width: 180px;
    text-align: center;
    background-color: white;
    position: absolute;
    color: black;
    display: none;
    border-radius: 5px;
    line-height: 0;
    z-index: 1;
  }
  .dropDownAnchor {
    color: #375876;
  }
  .dropDownOptions {
    background-color: white;
    line-height: 2;
    height: 4vh;
    padding-top: 0.5vh;
    margin: 5px;
  }
  .dropDownOptions:hover {
    background-color: #375876;
  }
  .dropDownOptions:hover h4 {
    color: white;
  }
  .cityBtnStyle {
    padding-top: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000000;
    font-size: 12px;
    font-family: 'Roboto';
  }
  .cityBtnStyle:hover {
    color: #b66d19;
  }
  #myDIVForMobile > nav > ul > li,
  #myDIVForMobile > nav > ul > li > a {
    height: 40px;
    padding-top: 15px;
    font-weight: bold;
    font-size: 12px;
  }

  @media only screen and (min-width: 767px) {
    .mobileViewStyle {
      display: none;
    }
  }
  @media only screen and (max-width: 767px) {
    .webViewStyle {
      display: none;
    }
    #ourDestinations {
      display: none;
    }
    #myDIVForMobile {
      display: none;
      line-height: normal;
    }
    .MuiButton-containedPrimary {
      margin-top: -20px;
    }
    .cityBtnStyle {
      padding-top: 12px;
      font-weight: 300;
      text-transform: uppercase;
      color: #000000;
      font-size: 1em;
      font-family: 'Roboto';
    }
  }
  @media only screen and (max-width: 660px) {
    #myDIVForMobile {
      display: none;
      line-height: normal;
    }
  }
  @media only screen and (max-width: 660px) {
    .cityBtnStyle {
      font-size: 10px;
    }
  }
  @media only screen and (max-width: 612px) {
    .cityBtnStyle {
      font-size: 8px;
    }
  }
  @media only screen and (max-width: 480px) {
    .title {
      font-size: 20px;
      color: #000000;
    }
  }

  @media only screen and (max-width: 370px) {
    .title {
      font-size: 18px;
      padding-top: 15px;
      color: #000000;
    }
  }
`;
