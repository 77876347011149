/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const messages = {
  headerPrague: () =>
    _t(translations.PageHeaderImage.headerPrague, 'Explore Prague'),
  headerNewYork: () =>
    _t(translations.PageHeaderImage.headerNewYork, 'Explore New York'),
  headerBarcelona: () =>
    _t(
      translations.PageHeaderImage.headerBarcelona,
      'Explore spots in Barcelona',
    ),
  headerAustin: () =>
    _t(translations.PageHeaderImage.headerAustin, 'Explore Austin'),
};
