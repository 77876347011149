/**
 *
 * GoogleAuth
 *
 */
import { GoogleLogin } from 'react-google-login';
import Button from '@mui/material/Button';
import styled from 'styled-components/macro';

interface Props {
  logInSuccess: any;
}

export default function GoogleAuth(props: Props) {
  const successGoogleResponse = async response => {
    let obj = {
      firstName: response.profileObj.givenName,
      lastName: response.profileObj.familyName,
      email: response.profileObj.email,
      token: response.accessToken,
      googleId: response.profileObj.googleId,
      imageURL: response.profileObj.imageUrl,
      lastLogin: new Date(),
    };
    props.logInSuccess(obj);
  };

  const failureGoogleResponse = async response => {
    alert(JSON.stringify(response));
  };
  return (
    <GoogleLoginWrapper>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
        buttonText="Login"
        onSuccess={successGoogleResponse}
        onFailure={failureGoogleResponse}
        cookiePolicy={'single_host_origin'}
        render={renderProps => (
          <Button
            type="submit"
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => renderProps.onClick()}
          >
            Sign In
          </Button>
        )}
      />
    </GoogleLoginWrapper>
  );
}

const GoogleLoginWrapper = styled.div`
  @media only screen and (max-width: 767px) {
    .MuiButton-label {
      font-size: xx-small;
    }
  }
`;
