import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { creatorProfilePageSaga } from './saga';
import { CreatorProfilePageState } from './types';
import { creatorProfile } from 'types/Shared';

export const initialState: CreatorProfilePageState = {
  error: false,
  success: false,
  loading: false,
  creatorProfile: undefined,
  creatorId: null,
};

const slice = createSlice({
  name: 'creatorProfilePage',
  initialState,
  reducers: {
    loadCreatorProfile(state, action: PayloadAction<number>) {
      state.creatorId = action.payload;
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    creatorProfileLoaded(state, action: PayloadAction<creatorProfile>) {
      state.creatorProfile = action.payload;
      state.success = true;
      state.loading = false;
    },
    creatorProfileLoadError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { actions: creatorProfilePageActions } = slice;

export const useCreatorProfilePageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: creatorProfilePageSaga });
  return { actions: slice.actions };
};
