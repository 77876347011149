/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LogRocket from 'logrocket';
import FullPageRoutes from './routes/FullPageRoutes';
import EmptyPageRoutes from './routes/EmptyPageRoutes';
import NoFooterRoute from './routes/NoFooterRoute';
import SignUpLandingPage from 'app/pages/SignUpLandingPage/Loadable';
import ContactPage from 'app/pages/ContactPage/Loadable';
import Thankyou from 'app/pages/ContactPage/Thankyou/Loadable';
import CareersPage from 'app/pages/CareersPage/Loadable';
import ExplorePage from 'app/pages/ExplorePage/Loadable';
import FAQPage from 'app/pages/FaqPage/Loadable';
import HomePage from 'app/pages/HomePage/Loadable';
import MapPage from 'app/pages/MapPage/Loadable';
import PrivacyPage from 'app/pages/PrivacyPage/Loadable';
import NotFoundPage from 'app/pages/NotFoundPage/Loadable';
import TermConditionPage from 'app/pages/TermConditionPage/Loadable';
import { CreatorMapPage } from './pages/CreatorMapPage/Loadable';
import UserProfliePage from './pages/UserProfliePage/index';
import CreatorProfliePage from './pages/CreatorProfliePage/index';

export function App(props: any) {
  const { i18n } = useTranslation();
  LogRocket.init('334xtb/travelsighted');
  return (
    <>
      <Helmet
        titleTemplate="%s - Travelsighted"
        defaultTitle="Travelsighted"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta
          name="description"
          content="Travel site that makes you travelsighted.  See the places you are going to you can really see the places you are going."
        />
      </Helmet>

      <Switch>
        <EmptyPageRoutes exact path="/" component={SignUpLandingPage} />
        <FullPageRoutes path="/home" component={HomePage} />
        <FullPageRoutes path="/explore/:city" component={ExplorePage} />
        <NoFooterRoute path="/map/:city/:placeId/:clipId" component={MapPage} />
        <NoFooterRoute path="/map/:city" component={MapPage} />
        <FullPageRoutes path="/contact/thankyou" component={Thankyou} />
        <FullPageRoutes path="/contact" exact component={ContactPage} />
        <FullPageRoutes path="/faq" component={FAQPage} />
        <FullPageRoutes path="/privacy" component={PrivacyPage} />
        <FullPageRoutes path="/careers" component={CareersPage} />
        <FullPageRoutes path="/termcondition" component={TermConditionPage} />
        <FullPageRoutes path="/user/profile/:id" component={UserProfliePage} />
        <FullPageRoutes
          path="/creator/profile/:id"
          component={CreatorProfliePage}
        />
        <EmptyPageRoutes
          path="/creator/Map/:creatorid/:city"
          component={CreatorMapPage}
        />
        <FullPageRoutes component={NotFoundPage} />
      </Switch>
    </>
  );
}
