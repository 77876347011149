import React from 'react';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';

const ProviderWrapper: any = ({ children, store }) => (
  <Provider store={store}>
    <CssBaseline />
    <Router>{children}</Router>
    <GlobalStyle />
  </Provider>
);

export default ProviderWrapper;
