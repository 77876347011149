import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.headerSlices || initialState;

export const selectHeaderSlices = createSelector([selectSlice], state => state);

export const selectReqGoogleAuth = createSelector(
  [selectSlice],
  state => state.reqGoogleAuthData,
);

export const selectGoogleAuth = createSelector(
  [selectSlice],
  state => state.googleAuthData,
);

export const selectGoogleAuthSuccess = createSelector(
  [selectSlice],
  state => state.success,
);

export const selectGoogleAuthLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectGoogleAuthError = createSelector(
  [selectSlice],
  state => state.error,
);
