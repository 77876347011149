import styled from 'styled-components/macro';

interface DivProps {
  headerImg: string;
}

const StyledHeaderImage = styled.div<DivProps>`
  grid-area: header-image;
  //  background-image: url('${p => p.headerImg}');
  -webkit-background-size: cover !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat;
  opacity: 1;
  position: relative;
  height: 77px;
  overflow: hidden;

  .image-holder {
    position: absolute;
    height: 200px;
    width: 100%;
    top: 0;
    left: 0;
    background-position: center !important;
    background-repeat: no-repeat;
    z-index: 0;
    -o-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
    -webkit-transition: opacity 0.3s linear;
    opacity: 0;
    display: block;
  }

  :before {
    background: rgba(55, 88, 119, 1);
    position: absolute;
    content: '';
    width: 100%;
    height: 200px;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .title {
    color: #fff;
    line-height: 1.13333rem;
    font-size: 1.66667rem;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
    font-family: 'Muli', sans-serif;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    z-index: 5;
  }

  .header-text-overlay {
    position: relative;
    z-index: 20;
    padding-top: 90px;
  }
`;

export default StyledHeaderImage;
