/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { Route } from 'react-router-dom';
import Header from 'app/components/Header';
import NoFooterLayout from 'app/components/layout/Page';

const FullPageRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <NoFooterLayout>
          <Header showCityLink />
          <Component {...matchProps} />
        </NoFooterLayout>
      )}
    />
  );
};

export default FullPageRoutes;
