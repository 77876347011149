/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
// Use consistent styling
import 'sanitize.css/sanitize.css';
// Import root app
import { App } from 'app';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from 'store/configureStore';
import reportWebVitals from 'reportWebVitals';
import { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import { CookieComponent } from './app/components/CookieComponent/Loadable';
// Initialize languages
import './locales/i18n';
import ProviderWrapper from 'ProviderWrapper';
import GA4React from 'ga-4-react';
const ga4react = new GA4React(
  process.env.REACT_APP_GOOGLE_ANALYTICS_KEY as string,
);

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

(async () => {
  try {
    const isConsent = getCookieConsentValue();
    if (isConsent !== 'false') {
      await ga4react.initialize();
    } else {
      Cookies.remove('_ga');
      Cookies.remove(
        ('_ga_' +
          process.env.REACT_APP_GOOGLE_ANALYTICS_KEY_FOR_COOKIE) as string,
      );
    }
  } catch (error) {}
  ReactDOM.render(
    <ProviderWrapper store={store}>
      <HelmetProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </HelmetProvider>
      <CookieComponent />
    </ProviderWrapper>,
    MOUNT_NODE,
  );
})();

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
