/**
 *
 * LoadingIndicator
 *
 */
import * as React from 'react';

export const LoadingIndicator = () => (
  <>
    <svg
      version="1.1"
      id="L4"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 0 0"
      style={{ position: 'absolute' }}
    >
      <circle fill="#375877" stroke="none" cx="5" cy="20" r="2">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill="#375877" stroke="none" cx="15" cy="20" r="2">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
      <circle fill="#375877" stroke="none" cx="25" cy="20" r="2">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.3"
        />
      </circle>
    </svg>
  </>
);
