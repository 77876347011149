/**
 *
 * Asynchronously loads the component for TermConditionPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const TermConditionPage = lazyLoad(
  () => import('./index'),
  module => module.TermConditionPage,
);

export default TermConditionPage;
