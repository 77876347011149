import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { messages } from './messages';
import { Input, Form } from 'semantic-ui-react';
import toast, { Toaster } from 'react-hot-toast';
import Button from '@mui/material/Button';

interface Props {
  onSubmit: any;
  error: boolean;
  loading: boolean;
  success: boolean;
}

export default function FooterTextInput(props: Props) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState('');

  const successToast = () =>
    toast.success('Your wishlist is successfully added.');

  const validationToast = () =>
    toast.error('You must enter a valid creator, city or sight to see.');

  const handleChange = e => {
    const { value } = e.target;
    setValue(value);
  };

  const handleSubmit = () => {
    let error = '';
    if (value.length < 5) {
      error = 'Desire ' + t(...messages.textFieldRequiredLength());
    }

    if (error !== '') {
      validationToast();
    } else {
      const obj = { name: value };
      props.onSubmit([obj]);
      successToast();
    }
  };
  return (
    <Form
      onSubmit={e => {
        handleSubmit();
      }}
    >
      <Input
        value={value}
        onChange={handleChange}
        placeholder="Make a Request ..."
        className="txtInput"
      />
      <Button type="submit" variant="contained" color="primary">
        Request
      </Button>
      <Toaster />
    </Form>
  );
}
