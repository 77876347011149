import React from 'react';
import { Route } from 'react-router-dom';

const EmptyPageRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <>
          <Component {...matchProps} />
        </>
      )}
    />
  );
};

export default EmptyPageRoutes;
