import { API_BASE } from 'app/configuration';
import { request } from 'utils/request';
import { takeLatest, call, put } from 'redux-saga/effects';
import { footerActions as actions } from '.';
import { FooterWishList } from 'types/Shared';

export function* postWishList(action) {
  const body = JSON.stringify(action.payload[0]),
    headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    method = 'POST',
    requestURL = `${API_BASE}/wishList`;

  try {
    const results: FooterWishList[] = yield call(request, requestURL, {
      headers,
      method,
      body,
    });

    yield put(actions.footerCityInserted(results));
  } catch (err) {
    yield put(actions.footerCityInsertionError(err));
  }
}

export function* footerSaga() {
  yield takeLatest(actions.insertingFooterCity.type, postWishList);
}
