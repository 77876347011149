import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state?.creatorProfilePage || initialState;

export const selectCreatorProfilePage = createSelector(
  [selectSlice],
  state => state,
);

export const selectCreatorProfile = createSelector(
  [selectSlice],
  state => state.creatorProfile,
);

export const selectCreatorId = createSelector(
  [selectSlice],
  state => state.creatorId,
);

export const selectCreatorProfileSuccess = createSelector(
  [selectSlice],
  state => state.success,
);

export const selectCreatorProfileLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectCreatorProfileError = createSelector(
  [selectSlice],
  state => state.error,
);
