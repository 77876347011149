let baseUrl: string = process.env.REACT_APP_BASEURL as string;
let apiKey: string = process.env.REACT_APP_APIKEY as string;
let googleAnalytics: string = '';
let mapsKey: string = process.env.REACT_APP_MAP_KEY as string;
const mapsId: string = process.env.REACT_APP_MAP_STYLE_ID as string;

export const API_BASE: string = baseUrl;
export const YOUTUBE_API_KEY: string = apiKey;
export const GOOGLE_ANALYTICS_KEY: string = googleAnalytics;
export const GOOGLE_MAPS_KEY: string = mapsKey;
export const GOOGLE_MAPS_STYLE_ID: string = mapsId;
