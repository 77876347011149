import { call, put, select, takeLatest } from 'redux-saga/effects';
import { headerSlicesActions as actions } from '.';

import { API_BASE } from 'app/configuration';
import { request } from 'utils/request';
import { selectReqGoogleAuth } from './selectors';
import { GoogleAuth } from 'types/Shared';

export function* headerSlicesSaga() {
  yield takeLatest(actions.insertingGoogleAuthData, googleAuth);
}

export function* googleAuth(action) {
  try {
    const getReqGoogleAuthData: GoogleAuth = yield select(selectReqGoogleAuth);

    const body = JSON.stringify(getReqGoogleAuthData),
      headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method = 'POST',
      requestURL = `${API_BASE}/login`;

    const result = yield call(request, requestURL, {
      headers,
      method,
      body,
    });

    yield put(actions.googleAuthDataInserted(result));
  } catch (err) {
    yield put(actions.googleAuthDataInsertionError(err));
  }
}
