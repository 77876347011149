import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { footerSaga } from './saga';
import { FooterState } from './types';
import { FooterWishList } from 'types/Shared';

export const initialState: FooterState = {
  error: false,
  success: false,
  loading: false,
  name: [],
};

const slice = createSlice({
  name: 'footer',
  initialState,
  reducers: {
    insertingFooterCity(state, action: PayloadAction<FooterWishList[]>) {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    footerCityInserted(state, action: PayloadAction<FooterWishList[]>) {
      state.name = action.payload;
      state.success = true;
      state.loading = false;
    },
    footerCityInsertionError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { actions: footerActions } = slice;

export const useFooterSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: footerSaga });
  return { actions: slice.actions };
};
