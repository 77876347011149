import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.footer || initialState;

export const wishListSubmittedSuccess = createSelector(
  [selectSlice],
  state => state.success,
);

export const wishListSubmittedLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const wishListSubmittedError = createSelector(
  [selectSlice],
  state => state.error,
);
