/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const messages = {
  textFieldRequired: () =>
    _t(
      translations.LandingPage.textFieldRequired,
      'This field cannot be blank',
    ),
  textFieldRequiredLength: () =>
    _t(
      translations.LandingPage.textFieldRequiredLength,
      'field must have more than 4 characters',
    ),
};
