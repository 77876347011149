import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { headerSlicesSaga } from './saga';
import { HeaderSlicesState } from './types';
import { GoogleAuth } from 'types/Shared';

export const initialState: HeaderSlicesState = {
  error: false,
  success: false,
  loading: false,
  googleAuthData: null,
  reqGoogleAuthData: null,
};

const slice = createSlice({
  name: 'headerSlices',
  initialState,
  reducers: {
    insertingGoogleAuthData(state, action: PayloadAction<GoogleAuth>) {
      state.loading = true;
      state.success = false;
      state.error = false;
      state.reqGoogleAuthData = action.payload;
    },
    googleAuthDataInserted(state, action: PayloadAction<GoogleAuth>) {
      state.googleAuthData = action.payload;
      state.success = true;
      state.loading = false;
    },
    googleAuthDataInsertionError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { actions: headerSlicesActions } = slice;

export const useHeaderSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: headerSlicesSaga });
  return { actions: slice.actions };
};
