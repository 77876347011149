/**
 *
 * Asynchronously loads the component for ExplorePage
 *
 */

import { lazyLoad } from 'utils/loadable';

const ExplorePage = lazyLoad(
  () => import('./index'),
  module => module.ExplorePage,
);

export default ExplorePage;
