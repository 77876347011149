/**
 *
 * Asynchronously loads the component for CareersPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const CareersPage = lazyLoad(
  () => import('./index'),
  module => module.CareersPage,
);

export default CareersPage;
