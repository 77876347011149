/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const messages = {
  Title: () => _t(translations.footer.title, 'Travelsighted'),
  Creator: () =>
    _t(
      translations.footer.creators,
      'CONNECTING CREATORS, TRAVELERS AND CITIES FOR A BETTER EXPERIENCE',
    ),
  AddCreator: () =>
    _t(
      translations.footer.addCreators,
      'TELL US THE CREATORS, CITIES AND SIGHTS WE SHOULD ADD NEXT',
    ),
  FollowUs: () => _t(translations.footer.followUs, 'FOLLOW US'),
  ContactUs: () => _t(translations.footer.contactUS, 'CONTACT US'),
  CreatorLink: () => _t(translations.footer.creatorLink, 'FOR CREATORS'),
  CopyRight: () =>
    _t(translations.footer.copyRight, 'Travelsighted All Rights Reserved'),
  Privacy: () => _t(translations.footer.privacy, 'PRIVACY POLICY'),
  TermCondition: () =>
    _t(translations.footer.termCondition, 'TERMS AND CONDITIONS'),
};
