/**
 *
 * UserProfliePage
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
// import { useTranslation } from 'react-i18next';
// import { messages } from './messages';
// import { useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PageHeaderImage from 'app/components/PageHeaderImage';
interface Props {}

export default function UserProfliePage(props: Props) {
  // const { t, i18n } = useTranslation();
  // let match = useRouteMatch();

  // const userId: string = match.params['id'] ?? 'unknown';

  return (
    <>
      <Helmet>
        <title>User Profile Page</title>
        <meta name="description" content="User Profile Page" />
      </Helmet>
      <PageHeaderImage city={''} />
      <Wrapper>
        <h1>jdfhksjdfhkj</h1>
        <h1>jdfhksjdfhkj</h1>
        <h1>jdfhksjdfhkj</h1>
        <h1>jdfhksjdfhkj</h1>
        <h1>jdfhksjdfhkj</h1>
        <h1>jdfhksjdfhkj</h1>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100%;
`;
