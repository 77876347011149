import { API_BASE } from 'app/configuration';
import { request } from 'utils/request';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { creatorProfilePageActions as actions } from '.';
import { creatorProfile } from 'types/Shared';
import { selectCreatorId } from './selectors';

export function* creatorProfilePageSaga() {
  yield takeLatest(actions.loadCreatorProfile.type, loadCreatorsProfile);
}

export function* loadCreatorsProfile() {
  try {
    const creatorId: number = yield select(selectCreatorId);
    const requestURL = `${API_BASE}/creators/profile/stats/${creatorId}`;
    const results: creatorProfile[] = yield call(request, requestURL);
    yield put(actions.creatorProfileLoaded(results[0]));
  } catch (err) {
    yield put(actions.creatorProfileLoadError(err));
  }
}
