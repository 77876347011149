/**
 *
 * Asynchronously loads the component for CreatorMapPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CreatorMapPage = lazyLoad(
  () => import('./index'),
  module => module.CreatorMapPage,
);
