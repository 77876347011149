/**
 *
 * PageHeaderImage
 *
 */

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PrageImg from 'app/images/prague.jpg';
import AustinImg from 'app/images/austin.jpg';
import NewYorkImg from 'app/images/new_york.jpg';
import BarcelongImg from 'app/images/barcelona.jpg';
import { messages } from './messages';
import StyledHeaderImage from './StyledHeaderImage';

interface PageHeaderImageProps {
  city: string | null;
}

// interface DivProps {
//   headerImg: string;
// }

const PageHeaderImage: FC<PageHeaderImageProps> = ({
  city,
}: PageHeaderImageProps) => {
  const { t } = useTranslation();

  let headerImg = NewYorkImg;

  if (city === 'prague') {
    headerImg = PrageImg;
  } else if (city === 'new_york') {
    headerImg = NewYorkImg;
  } else if (city === 'barcelona') {
    headerImg = BarcelongImg;
  } else if (city === 'austin') {
    headerImg = AustinImg;
  } else if (city === 'default') {
    headerImg = 'https://source.unsplash.com/collection/8909725';
  }

  let headerTitle: string = 'Fuck we dont know where you are!!';
  if (city) {
    if (city.toLowerCase() === 'prague') {
      headerTitle = t(messages.headerPrague());
    } else if (city.toLowerCase() === 'new_york') {
      headerTitle = t(...messages.headerNewYork());
    } else if (city.toLowerCase() === 'barcelona') {
      headerTitle = t(...messages.headerBarcelona());
    } else if (city.toLowerCase() === 'austin') {
      headerTitle = t(...messages.headerAustin());
    } else if (city.toLowerCase() === 'default') {
      headerTitle = '';
    }
  }

  return (
    <StyledHeaderImage headerImg={headerImg}>
      <div className="header-text-overlay">
        <h2 className="title">{headerTitle}</h2>
      </div>
    </StyledHeaderImage>
  );
};

export default PageHeaderImage;
